<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <nav class="breadcrumb">
                    <ol class="breadcrumb-wrapper">
                        <li class="breadcrumb-item"><a href="javascript:">관리자</a></li>
                        <li class="breadcrumb-item"><a href="javascript:">공지사항 입력</a></li>
                    </ol>
                </nav>
                <h2 class="page-title">공지사항</h2>
                <div class="page-title-bottom">
                    <!--                    <a href="" class="link">-->
                    <!--                        <strong class="text-primary">미리보기</strong>-->
                    <!--                    </a>-->
                </div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-contents -->
            <div class="page-contents">
                <div class="board-form-container">
                    <div>
                        <div class="form-fields">
                            <!-- field:타이틀 입력 -->
                            <div class="form-field">
                                <label class="form-label">공지 유형</label>
                                <select v-model="params.boardTypeDcd" class="form-input" @change="selectBoardType">
                                    <option value="" disabled>공지 유형을 선택해주세요.</option>
                                    <option v-for="(dcd, index) in boardTypeDcds" :value="dcd.dcd" :key="index">{{ dcd.dcdKoVal }}</option>
                                </select>
                            </div>

                            <div v-if="params.boardTypeDcd === domainCode.BOARD_TYPE_BUSINESS" class="form-field">
                                <label class="form-label">사업공지 구분</label>
                                <select v-model="params.boardGbDcd" class="form-input">
                                    <option :value="null" disabled>사업공지 구분을 선택해주세요.</option>
                                    <option v-for="(dcd, index) in boardGbDcds" :value="dcd.dcd" :key="index">{{ dcd.dcdKoVal }}</option>
                                </select>
                            </div>

                            <div class="form-field">
                                <label class="form-label">타이틀 입력</label>
                                <input v-model.trim="params.boardNm" type="text" class="form-input" placeholder="타이틀 입력" />
                            </div>

                            <div class="form-field">
                                <label class="form-label">첨부파일</label>
                                <div class="form-file-container" style="height: initial; min-height: 59px">
                                    <label v-if="attachments.length === 0" class="form-label" style="padding: 0">첨부파일을 등록해주세요.</label>
                                    <div v-else class="file-list" style="flex-wrap: wrap">
                                        <div v-for="(item, index) in attachments" class="file" :key="index">
                                            <span class="file-text">{{ item.fileNm }}</span>
                                            <button class="btn-delete" @click="removeAttachment(index)"><i class="icon-delete"></i></button>
                                        </div>
                                    </div>

                                    <div class="form-file">
                                        <input
                                            ref="attachmentFile"
                                            type="file"
                                            class="form-file-input"
                                            id="board_attachment"
                                            multiple
                                            @change="selectAttachment" />
                                        <label for="board_attachment" class="form-file-label text-primary">파일찾기</label>
                                    </div>
                                </div>
                            </div>

                            <div class="form-field">
                                <label class="form-label">내용 입력</label>
                                <!-- [!DEV] 에디터 영역, 높이 임의 설정 -->
                                <div class="editor-container swcc-editor-container" style="height: 957px">
                                    <div v-show="false" ref="pageEditor"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <!-- page-bottom -->
        <div class="page-bottom bottom-fixed">
            <div>
                <div class="bottom-row-container bottom-component">
                    <div class="bottom-side bottom-side-fluid">
                        <div class="btn-actions">
                            <button v-if="bno > 0" class="btn-action btn-text" @click="deleteBoard"><span class="text text-pink">삭제</span></button>
                            <button class="btn-action btn-text" @click="saveBoard">
                                <span class="text">{{ bno > 0 ? '수정' : '등록' }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //page-bottom -->
    </main>
</template>
<style></style>

<script>
import {computed, onMounted, onUnmounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {collectionCheck, getItem, isSuccess, lengthCheck, setParams, timestampToDateFormat} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {setPageEditor} from '@/assets/js/editor/editor.utils';
import {hideLoading, showLoading} from '@/assets/js/common.alert';
import {getAuthSession} from '@/assets/js/modules/auth/module';
import {getDomainCodes} from '@/assets/js/modules/common/module';
import {validateParams} from '@/assets/js/common.validate';
import {domainCode} from '@/assets/js/domain.code';

export default {
    name: 'NoticeEdit',
    components: {},
    setup: function () {
        showLoading();

        const route = useRoute();
        const router = useRouter();
        const pageEditor = ref(null);
        const session = computed(getAuthSession);

        const bno = computed(() => (route.params.bno ? parseInt(route.params.bno) : 0));

        const exclusives = [
            domainCode.BOARD_TYPE_CONSULTING,
            domainCode.BOARD_TYPE_CONVERSION,
            domainCode.BOARD_TYPE_VISUALIZATION,
            domainCode.BOARD_TYPE_ANALYTICS,
        ];

        const boardTypeDcds = computed(() => getDomainCodes('1002', exclusives));
        const boardGbDcds = computed(() => getDomainCodes('1004'));

        const params = reactive({
            bno: 0,
            boardNm: '',
            boardContents: '',
            boardTypeDcd: '',
            boardGbDcd: null,
        });

        const attachmentFile = ref(null);
        const attachments = ref([]);

        const selectAttachment = () => {
            if (attachmentFile.value.files && attachmentFile.value.files.length > 0) {
                for (let i = 0; i < attachmentFile.value.files.length; i++) {
                    attachments.value.push({
                        fileNm: attachmentFile.value.files[i].name,
                        fileSize: attachmentFile.value.files[i].size,
                        fileExtension: attachmentFile.value.files[i].name.substring(attachmentFile.value.files[i].name.lastIndexOf('.') + 1),
                        binary: attachmentFile.value.files[i],
                    });
                }
            }
            attachmentFile.value.value = null;
        };

        const removes = ref([]);

        const removeAttachment = index => {
            if (attachments.value[index].fileNo > 0) removes.value.push(attachments.value[index]);
            attachments.value.splice(index, 1);
        };

        const selectBoardType = () => {
            params.boardGbDcd = null;
        };

        let tryCount = 0;
        let instance = null;

        const setEditorInstance = editor => {
            instance = editor;
            editor.setData(params.boardContents);
            hideLoading();
        };

        const getBoardNotice = () => {
            if (bno.value > 0) {
                ApiService.get('/v1/boards', bno.value)
                    .then(res => {
                        if (lengthCheck(res)) {
                            const item = getItem(res);
                            setParams(params, item);

                            if (collectionCheck(item.boardFiles, 'fileNo')) {
                                attachments.value.push(...item.boardFiles);
                            }
                        }
                        setPageEditor(pageEditor.value, tryCount, setEditorInstance, '/v1/notice/uploads');
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                    });
            }
        };

        const validateKeys = computed(() => {
            if (params.boardTypeDcd === domainCode.BOARD_TYPE_BUSINESS) {
                return {
                    boardTypeDcd: '공지 유형을 선택해주세요.',
                    boardGbDcd: '사업공지 구분을 선택해주세요.',
                    boardNm: '타이틀을 입력해주세요.',
                    boardContents: '내용을 입력해주세요.',
                };
            }
            return {
                boardTypeDcd: '공지 유형을 선택해주세요.',
                boardNm: '타이틀을 입력해주세요.',
                boardContents: '내용을 입력해주세요.',
            };
        });

        const saveBoard = async () => {
            params.boardContents = instance ? instance.getData() : params.boardContents;

            if (validateParams(validateKeys.value, params, false)) {
                const actionText = params.bno > 0 ? '수정' : '등록';

                if (confirm(`공지사항을 ${actionText}하시겠습니까?`)) {
                    showLoading();

                    try {
                        const saveAction = params.bno > 0 ? ApiService.update('/v1/boards', params.bno, params) : ApiService.post('/v1/boards', params);
                        const res = await saveAction;
                        if (isSuccess(res)) {
                            if (res.bno > 0) params.bno = res.bno;

                            for (let i = 0; i < attachments.value.length; i++) {
                                if (attachments.value[i].binary) {
                                    const uploadRes = await ApiService.upload(`/v1/boards/notice/${params.bno}/files/uploads`, [attachments.value[i].binary]);
                                    if (uploadRes && uploadRes.uploadKey) {
                                        attachments.value[i].uploadKey = uploadRes.uploadKey;
                                    }
                                }
                            }

                            const adds = attachments.value
                                .filter(x => !x.fileNo && x.uploadKey)
                                .map(x => ({
                                    fileNm: x.fileNm,
                                    fileExtension: x.fileExtension,
                                    fileSize: x.fileSize,
                                    uploadKey: x.uploadKey,
                                }));

                            if (adds.length > 0 || removes.value.length > 0) {
                                // 첨부파일 또는 삭제될 첨부파일이 있을 경우 처리
                                await ApiService.post(`/v1/boards/${params.bno}/files`, {
                                    adds,
                                    removes: removes.value,
                                });
                            }

                            alert(`공지사항이 ${actionText}되었습니다.`);
                            await router.push({name: 'NoticeView', params: {bno: params.bno}});
                        } else {
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        }
                    } catch (e) {
                        console.error(e);
                        hideLoading();
                        alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                    }

                    hideLoading();
                }
            }
        };

        const deleteBoard = () => {
            if (confirm(`공지사항을 삭제하시겠습니까?`)) {
                showLoading();
                ApiService.delete('/v1/boards', bno.value)
                    .then(res => {
                        hideLoading();
                        if (isSuccess(res)) {
                            if (res.bno > 0) params.bno = res.bno;
                            alert(`공지사항이 삭제되었습니다.`);
                            router.push({name: 'Notice'});
                        } else {
                            alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                        }
                    })
                    .catch(e => {
                        console.error(e);
                        hideLoading();
                        alert(`오류가 발생했습니다. 잠시후 다시 시도해주세요.`);
                    });
            }
        };

        onMounted(() => {
            if (session.value.manager) {
                if (bno.value > 0) {
                    getBoardNotice();
                } else {
                    setPageEditor(pageEditor.value, tryCount, setEditorInstance, '/v1/boards/uploads');
                }
            } else {
                hideLoading();
                router.push({name: 'Notice'});
            }
        });

        onUnmounted(() => {
            if (instance !== null) {
                instance.destroy();
            }
        });

        return {
            bno,
            boardTypeDcds,
            boardGbDcds,
            params,
            pageEditor,
            attachmentFile,
            attachments,
            selectAttachment,
            removeAttachment,
            saveBoard,
            deleteBoard,
            selectBoardType,
            timestampToDateFormat,
            domainCode,
        };
    },
};
</script>
